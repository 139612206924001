<template>
<div>
  <treeselect
    v-model="getValue"
    class="form-control form-control-solid select-tree px-0 py-0 h-100"
    :multiple="multiple"
    :valueFormat="'object'"
    :flat="flat"
    :searchable="searchable"
    noChildrenText=""
    noOptionsText=""
    :noResultsText="$t('BASE.EMPTY_DATA')"
    :default-expand-level="1"
    :placeholder="placeholder"
    :clearable="clearable"
    :normalizer="normalizer"
    :options="options"
  >
    <div slot="value-label" slot-scope="{ node }">
      {{ node.raw[textField] }}
    </div>
    <div slot="option-label" slot-scope="{ node }">
      <div class="d-flex align-items-center">
        <div v-if="multiple" class="svg-block bg-light border d-flex align-items-center justify-content-center mr-2">
          <span v-if="isChecked(node.raw[valueField])" class="svg-icon svg-icon-info">
            <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
          </span>
        </div>
        <div class="text-dark py-1">
          {{ node.raw[textField] }}
        </div>
      </div>
    </div>
  </treeselect>
</div>
</template>

<script>

export default {
  name: 'SelectTree',
  model: {
    event: 'input',
    prop: 'value',
  },
  props: {
    value: null,
    multiple: {
      type: Boolean,
    },
    flat: {
      type: Boolean,
    },
    searchable: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => [],
    },
    valueField: {
      type: String,
      default: 'uuid',
    },
    textField: {
      type: String,
      default: 'name',
    },
    childrenField: {
      type: String,
      default: 'children',
    },
    disabled: {
      type: Boolean,
    },
    state: {
      type: Boolean,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    isChecked() {
      return (val) => this.value && this.value.map((m) => m[this.valueField]).includes(val)
    },
    getValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    options() {
      return this.onRenderItems(this.items)
    },
  },
  methods: {
    onRenderItems(items) {
      return items.map((item) => {
        if (item.children && item.children.length) {
          item.children = this.onRenderItems(item.children)
          return item
        }
        /*eslint-disable */
            const { children, ...rest } = item
            return rest
            /* eslint-enable */
      })
    },
    normalizer(item) {
      const obj = {
        id: item[this.valueField],
        label: item[this.textField],
      }
      if (item[this.childrenField] && item[this.childrenField].length) obj.children = item[this.childrenField]
      return obj
    },
  },
}
</script>
<style lang="scss">
  .select-tree{
    .vue-treeselect__menu{
      border: 1px solid #F3F6F9;
    }
    .vue-treeselect__control{
      border: none;
      background: transparent;
    }
    .vue-treeselect__multi-value-item {
      background: #dee2e6;
      color: #181C32;
      .vue-treeselect__value-remove{
        color: #181C32;
      }
    }
    .svg-block{
      width: 18px;
      min-width: 18px;
      height: 18px;
      border-radius: 4px;
    }
    .vue-treeselect__option--highlight{
      background: transparent;
    }
    .vue-treeselect__checkbox-container{
      display: none;
    }
    .vue-treeselect__tip{
      .vue-treeselect__icon-container{
        display: none;
      }
    }
  }
</style>
